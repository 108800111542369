import React from 'react';
import './MedidoresF.css';
import portada from './img/portada_3.webp';
import medidor from './img/medidor-de-fuerza.webp';
import tensiometro from './img/tensiometro.webp';
import { Helmet } from 'react-helmet';

function MedidoresF() {
  return (
    <>
    <Helmet>
        <title>Interphase - Ingeniería Eléctrica y Montajes</title>
        <meta name="description" content="Una celdas de carga (también conocidas como load cells o loadcell) esta clasificada como un transductor de fuerza. Este artefacto convierte la fuerza o peso a una señal eléctrica. El detector de deformación es el corazón de una celda de carga. " />
    </Helmet>
     <div className='medidor-f-container'>
        <div className="portada-container">
            <img src={portada} alt="medidores de fuerza" className="image-style" />
            <p className="text-overlay"><span style={{ color: 'red' }}>M</span>edidores de <span style={{ color: 'red' }}>F</span>uerza</p>
        </div>
        <div className='descripcion-container'>
          <h2 style={{ color: 'black' }}>
            <span style={{ color: 'red' }}> Maximiza </span>la eficiencia y <span style={{ color: 'red' }}>seguridad</span> de tus operaciones de remolque con nuestro Sistema Integral de Dinamómetro
          </h2>
          <p>
            Nuestra solución de vanguardia combina una Célula de Carga de alta precisión con monitorización y control en tiempo real, permitiéndote medir con exactitud la fuerza de tracción de tus remolcadores. Con nuestro sistema, podrás:
          </p>
          <div className="contenedor-imagenes-medidores">
            <div className="beneficios">
              <div className="beneficio">
                <p>Optimizar el rendimiento de tus remolcadores</p>
              </div>
              <div className="beneficio">
                <p>Reducir el riesgo de accidentes y daños</p>
              </div>
              <div className="beneficio">
                <p>Mejorar la toma de decisiones con datos precisos y confiables</p>
              </div>
            </div>
            <div className="imagen-contenedor">
              <img src={tensiometro} alt="Tensiometro" className="imagen-tensiometro" />
            </div>
          </div>
          <p>
            Nuestro Sistema Integral de Dinamómetro es la herramienta perfecta para empresas que buscan mejorar la eficiencia y seguridad en sus operaciones de remolque.
          </p>
          <h3>Diseño e Instalación de Celdas de Carga y Monitoreo en Tiempo Real</h3>
          <p>
            La medición precisa de la fuerza es crucial para garantizar la seguridad y eficiencia en operaciones de remolque. Nuestro sistema de medición de fuerza utiliza celdas de carga y monitoreo en tiempo real para proporcionar lecturas precisas y confiables.
          </p>
          <div className="imagen-medidor">
            <img src={medidor} alt="diseño-medidor-de-fuerza" className="image-style" />
          </div>
          <h3>¿Qué son las Celdas de Carga?</h3>
          <p>
            Las celdas de carga son transductores de fuerza que convierten la fuerza o peso en una señal eléctrica. Están clasificadas como load cells o loadcell y son el corazón de nuestro sistema de medición de fuerza.
          </p>
          <h3>¿Cómo funcionan?</h3>
          <p>
            El detector de deformación es el corazón de una celda de carga. Cambia su resistencia cuando está fatigado (esforzado). Esta señal eléctrica se procesa por una CPU que convierte en un valor medible en Toneladas, permitiendo su fácil lectura, ajuste y calibración mediante un Display HMI.
          </p>
          <h3>Ventajas de nuestro sistema</h3>
          <ul>
            <li>Precisión sin precedentes en la medición de fuerza</li>
            <li>Monitoreo y control en tiempo real</li>
            <li>Ajuste y calibración fácil</li>
            <li>Mejora la seguridad y eficiencia en operaciones de remolque</li>
          </ul>
          <h3>¿Quieres saber más?</h3>
          <p>
            Contáctanos para obtener más información y mejorar tus operaciones de remolque.
          </p>
        </div>
        <div className="descripcion-container-2">
          <h2 className="descripcion-titulo">
            <span className="resaltado">F</span>abricacion <span className="resaltado">C</span>eldas de <span className="resaltado">C</span>aja
          </h2>
          <p><b>(Modelo a medida)</b></p>
          <div className="descripcion-lista">
            <div className="columna">
              <ul>
                <li>Fabricadas para resistir sobrecargas y trabajar en las condiciones más adversas como ambientes marinos.</li>
                <li>Simplicidad a la hora de realizar su instalación.</li>
                <li>Excelente rango de precisión.</li>
                <li>Los amplificadores pueden ir integrados o externos (0/4…20 mA, -10…0…+10 V).</li>
              </ul>
            </div>
            <div className="columna">
              <ul>
                <li>Fabricación de celda de carga a medida de diferentes diámetros y longitudes para sustituir ejes existentes.</li>
                <li>Soporte elástico de acero inoxidable de alta resistencia.</li>
                <li>Disponible en versión ATEX (opcional).</li>
                <li>Sellado hermético con protección hasta IP 68.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MedidoresF
