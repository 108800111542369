import './Loading.css'; // Estilos para la pantalla de carga
import React from 'react';


function Loading() {
    
    return (
        <div className="loading-container">
            <div className="spinner"></div>
            <p>Cargando...</p>
        </div>
    );
}
export default Loading;
