import React from "react";
import "./Home.css"; // Asegúrate de tener este archivo CSS
import Servicios from "./img/Portada_final3.webp";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import image1 from './img/carrousel_2/puerto.webp';
import image2 from './img/carrousel_2/interphase_control.webp';
import image3 from './img/carrousel_2/trabajador_electrico.webp';
import image4 from './img/carrousel_2/generador_2.webp';
import image6 from './img/carrousel_2/obra_electrica.webp';


const HomeSection = () => {
    const carouselImages = [image1, image2, image3, image4, image6];
  return (
 <>
    <Helmet>
        <title>Interphase - Ingeniería Eléctrica y Montajes</title>
        <meta name="description" content="Interphase es una empresa de ingeniería eléctrica especializada en soluciones industriales innovadoras y confiables en Viña del Mar, Chile." />
    </Helmet>
    <div className="home-container">
              <div className="home-section">
                  <div className="text-content">
                      <p className="welcome-text">
                          <span className="arrow-icon">❯</span><span className="blinking-cursor">_</span> Bienvenido A <span className="brand-name">INTERPHASE</span><span id="console-text"></span>
                      </p>
                      <h1 className="title">
                          <span className="highlight">L</span>ISTO PARA<br />
                          <span className="highlight">T</span>RANSFORMAR<br />
                          <span className="highlight">S</span>U INDUSTRIA?
                      </h1>
                      <p className="description">
                          En <b>INTERPHASE</b>, fusionamos experiencia e innovación para
                          entregar soluciones que superan las expectativas de
                          nuestros clientes. Eficiencia y precisión son nuestros
                          pilares. ¿Quieres saber cómo podemos impulsar tu
                          negocio? Explora nuestros servicios y descubre el futuro de
                          la automatización.
                      </p>
                  </div>
                  <div className="image-content">
                      <Carousel showThumbs={false} showStatus={false} interval={5000} autoPlay infiniteLoop>
                          {carouselImages.map((image, index) => (
                              <div key={index}>
                                  <img
                                      src={image}
                                      alt={`Slide ${index + 1}`}
                                      className={index === 4 ? "bottom-focus" : ""} // Aplica estilo solo a la segunda imagen
                                  />
                              </div>
                          ))}
                      </Carousel>
                  </div>

              </div>
        <div className="services-section">
            <span><h2>Principales <span><br />Servicios</span></h2></span>
            <div className="services-grid">
                  <Link to="/monitoreo-alarmas"><div className="service-item">
                    <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M45.2721 4.55029H1.72798C0.773664 4.55029 0 5.30771 0 6.26194V33.8387C0 34.7931 0.773664 35.538 1.72798 35.538H14.9756V38.9939H14.1337C13.1794 38.9939 12.4057 39.7675 12.4057 40.7218C12.4057 41.6762 13.1794 42.4498 14.1337 42.4498H32.8663C33.8206 42.4498 34.5942 41.6762 34.5942 40.7218C34.5942 39.7675 33.8205 38.9939 32.8663 38.9939H32.0245V35.538H45.2721C46.2264 35.538 47 34.7931 47 33.8387V6.26194C47 5.30771 46.2263 4.55029 45.2721 4.55029ZM28.5687 38.9939H18.4313V35.538H28.5686V38.9939H28.5687ZM43.5441 32.0821H3.45588V8.00617H43.5441V32.0821Z" fill="#FC0505"/>
                        <path d="M39.5122 10.1949H7.37258C6.41826 10.1949 5.64459 10.9685 5.64459 11.9228V28.0503C5.64459 29.0046 6.41826 29.7782 7.37258 29.7782H39.5123C40.4666 29.7782 41.2403 29.0045 41.2403 28.0503V11.9227C41.2402 10.9685 40.4665 10.1949 39.5122 10.1949ZM37.8995 26.4375H9.10047V13.6507H37.8994V26.4375H37.8995Z" fill="#FC0505"/>
                        <path d="M33.8676 15.9547H24.9975C24.0432 15.9547 23.2697 16.7283 23.2697 17.6825C23.2697 18.6369 24.0433 19.4105 24.9975 19.4105H33.8676C34.8219 19.4105 35.5956 18.6369 35.5956 17.6825C35.5956 16.7283 34.8219 15.9547 33.8676 15.9547Z" fill="#FC0505"/>
                        <path d="M33.8676 20.7929H24.9975C24.0432 20.7929 23.2697 21.5666 23.2697 22.5208C23.2697 23.4751 24.0433 24.2488 24.9975 24.2488H33.8676C34.8219 24.2488 35.5956 23.4751 35.5956 22.5208C35.5956 21.5666 34.8219 20.7929 33.8676 20.7929Z" fill="#FC0505"/>
                        <path d="M16.8103 14.9787C14.0138 14.9787 11.7387 17.2538 11.7387 20.0503C11.7387 22.8468 14.0137 25.1219 16.8103 25.1219C19.6068 25.1219 21.8819 22.8468 21.8819 20.0503C21.8819 17.2538 19.6068 14.9787 16.8103 14.9787ZM16.8103 21.6661C15.9194 21.6661 15.1946 20.9412 15.1946 20.0504C15.1946 19.1595 15.9194 18.4346 16.8103 18.4346C17.7012 18.4346 18.426 19.1594 18.426 20.0504C18.4261 20.9412 17.7013 21.6661 16.8103 21.6661Z" fill="#FC0505"/>
                          </svg>
                    <h3>Monitoreo de alarmas</h3>
                          <p>Sistema de monitoreo avanzado: supervisa y
                              controla parámetros en tiempo real con nuestra
                              solución PLC Scada. Reduce riesgos y mejora la
                              toma de decisiones con nuestra plataforma de
                              monitoreo integral.</p>
                  </div></Link>
                  <Link to="/automatizacion-generadores"><div className="service-item">
                    <svg width="34" height="40" viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.0246723 19.8445L11.3333 0H30.2222L18.8889 16.7105H34L0 40L11.4761 19.8378L0.0246723 19.8445Z" fill="#FC0505"/>
                    </svg>
                          <h3>Automatización de Generadores</h3>
                              <p>Servicio de Integración de controladores <b>INTELIGEN</b> para Generadores Diesel.</p>
                  </div></Link>
                  <Link to="/medidores-de-fuerza"><div className="service-item">
                <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_246_123)">
                        <path d="M45.1043 15.4272L33.0848 41.325C32.0315 43.9891 30.1728 45.538 28.1283 45.538C26.4554 45.538 25.0304 44.6706 24.225 43.3076C23.3576 41.6967 23.4196 39.7141 24.4728 38.0413L40.2098 12.825C41.0152 11.5859 42.6261 11.1522 43.9272 11.8337C45.2283 12.5152 45.7239 14.0641 45.1043 15.4272ZM49.3174 22.9239C48.388 21.9326 46.8391 21.8706 45.7859 22.8C44.7946 23.7293 44.7326 25.2783 45.662 26.3315C49.7511 30.7924 52.0435 36.6163 52.0435 42.75C52.0435 44.113 53.1587 45.2283 54.5217 45.2283C55.8848 45.2283 57 44.113 57 42.75C57 35.3772 54.2739 28.3141 49.3174 22.9239ZM30.4826 18.8348C31.8457 18.9587 33.0228 17.9674 33.1467 16.6043C33.2706 15.2413 32.2793 14.0641 30.9163 13.9402C30.1109 13.8783 29.3054 13.8163 28.5 13.8163C12.763 13.8163 0 26.8272 0 42.8119C0 44.175 1.11522 45.2902 2.47826 45.2902C3.8413 45.2902 4.95652 44.175 4.95652 42.8119C4.95652 29.5533 15.4891 18.7728 28.5 18.7728C29.1815 18.7109 29.863 18.7728 30.4826 18.8348Z" fill="#FC0505"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_246_123">
                            <rect width="57" height="57" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                          <h3>Medidores de Fuerza</h3>
                          <p>Maximiza la eficiencia y seguridad de tus
                              operaciones de remolque con nuestra
                              solución de vanguardia de alta precisión con
                              monitorización y control de fuerza en tiempo
                                  real.</p>
                </div></Link>
                  <Link to="/luces-de-navegacion"><div className="service-item">
                    <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M37.9166 35.7265C37.9166 36.3923 37.35 36.9444 36.6666 36.9444H3.33331C2.64998 36.9444 2.08331 36.3923 2.08331 35.7265C2.08331 35.0607 2.64998 34.5085 3.33331 34.5085H36.6666C37.35 34.5085 37.9166 35.0607 37.9166 35.7265Z" fill="#FC0505"/>
                        <path d="M35 24.359V34.5085H5V24.359C5 16.288 11.7167 9.74359 20 9.74359C28.2833 9.74359 35 16.288 35 24.359Z" fill="#FC0505"/>
                        <path opacity="0.4" d="M20 6.08974C19.3167 6.08974 18.75 5.5376 18.75 4.87179V3.24786C18.75 2.58204 19.3167 2.02991 20 2.02991C20.6833 2.02991 21.25 2.58204 21.25 3.24786V4.87179C21.25 5.5376 20.6833 6.08974 20 6.08974Z" fill="#FC0505"/>
                        <path opacity="0.4" d="M8.33334 9.33763C8.01667 9.33763 7.7 9.22395 7.45 8.98036L5.78334 7.35643C5.3 6.88549 5.3 6.10601 5.78334 5.63507C6.26667 5.16413 7.06667 5.16413 7.55 5.63507L9.21667 7.259C9.7 7.72994 9.7 8.50942 9.21667 8.98036C8.96667 9.22395 8.65 9.33763 8.33334 9.33763Z" fill="#FC0505"/>
                        <path opacity="0.4" d="M31.6667 9.33763C31.35 9.33763 31.0333 9.22395 30.7833 8.98036C30.3 8.50942 30.3 7.72994 30.7833 7.259L32.45 5.63507C32.9333 5.16413 33.7333 5.16413 34.2167 5.63507C34.7 6.10601 34.7 6.88549 34.2167 7.35643L32.55 8.98036C32.3 9.22395 31.9833 9.33763 31.6667 9.33763Z" fill="#FC0505"/>
                    </svg>
                          <h3>Panel luces de navegación</h3>
                              <p>Sistema que garantiza las funciones de control y monitorización de las luces de navegación del buque.</p>
                </div></Link>
                  <Link to="/mantenimiento-generadores"><div className="service-item">
                    <svg width="53" height="50" viewBox="0 0 53 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_255_124)">
                            <path d="M44.1666 37.5V31.25C44.1595 27.9358 43.1052 24.6989 41.1384 21.9528C39.1716 19.2067 36.3816 17.0762 33.125 15.8334V27.0834H30.9166V10.4167H22.0833V27.0834H19.875V15.8334C16.6183 17.0762 13.8283 19.2067 11.8615 21.9528C9.89472 24.6989 8.84041 27.9358 8.83329 31.25V37.5H4.41663V41.6667H48.5833V37.5H44.1666Z" fill="#FC0505" />
                            <path d="M44.1666 37.5V31.25C44.1595 27.9358 43.1052 24.6989 41.1384 21.9528C39.1716 19.2067 36.3816 17.0762 33.125 15.8334V27.0834H30.9166V10.4167H22.0833V27.0834H19.875V15.8334C16.6183 17.0762 13.8283 19.2067 11.8615 21.9528C9.89472 24.6989 8.84041 27.9358 8.83329 31.25V37.5H4.41663V41.6667H48.5833V37.5H44.1666Z" fill="#FC0505" />
                        </g>
                        <defs>
                            <clipPath id="clip0_255_124">
                                <rect width="53" height="50" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                          <h3>Mantenimiento generadores</h3>
                              <p>Mantenimiento de generadores con servicios de inspección, diagnóstico, pruebas de rendimiento, y reparaciones con repuestos de alta calidad.</p>
                </div></Link>
                  <Link to="/calibracion"><div className="service-item">
                    <svg width="45" height="50" viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.41671 24.75C6.91671 24.8334 6.41671 24.8334 5.83337 24.8334C5.33337 24.8334 4.83337 24.8334 4.25004 24.75C3.75004 24.6667 3.33337 25.0834 3.33337 25.5834V48.3334C3.33337 49.25 4.08337 50 5.00004 50H6.66671C7.58337 50 8.33337 49.25 8.33337 48.3334V25.5834C8.33337 25 7.91671 24.6667 7.41671 24.75Z" fill="#FC0505" />
                        <path d="M24.0833 42.25C23.5833 42.3334 23.0833 42.3334 22.5 42.3334C22 42.3334 21.5 42.3334 20.9167 42.25C20.4167 42.1667 20 42.5834 20 43.0834V48.3334C20 49.25 20.75 50 21.6667 50H23.3333C24.25 50 25 49.25 25 48.3334V43.0834C25 42.5 24.5833 42.1667 24.0833 42.25Z" fill="#FC0505" />
                        <path d="M40.75 29.75C40.25 29.8334 39.75 29.8334 39.1666 29.8334C38.6666 29.8334 38.1666 29.8334 37.5833 29.75C37.0833 29.6667 36.6666 30.0834 36.6666 30.5834V48.3334C36.6666 49.25 37.4166 50 38.3333 50H40C40.9166 50 41.6666 49.25 41.6666 48.3334V30.5834C41.6666 30 41.25 29.6667 40.75 29.75Z" fill="#FC0505" />
                        <path d="M8.33333 8.91667V1.66667C8.33333 0.75 7.58333 0 6.66667 0H5C4.08333 0 3.33333 0.75 3.33333 1.66667V8.91667C1.33333 9.83333 0 11.8333 0 14.1667C0 17.4167 2.58333 20 5.83333 20C9.08333 20 11.6667 17.4167 11.6667 14.1667C11.6667 11.8333 10.3333 9.83333 8.33333 8.91667Z" fill="#FC0505" />
                        <path d="M25 26.4167V1.66667C25 0.75 24.25 0 23.3333 0H21.6666C20.75 0 20 0.75 20 1.66667V26.4167C18 27.3333 16.6666 29.3333 16.6666 31.6667C16.6666 34.9167 19.25 37.5 22.5 37.5C25.75 37.5 28.3333 34.9167 28.3333 31.6667C28.3333 29.3333 27 27.3333 25 26.4167Z" fill="#FC0505" />
                        <path d="M41.6667 13.9167V1.66667C41.6667 0.75 40.9167 0 40 0H38.3334C37.4167 0 36.6667 0.75 36.6667 1.66667V13.9167C34.6667 14.8333 33.3334 16.8333 33.3334 19.1667C33.3334 22.4167 35.9167 25 39.1667 25C42.4167 25 45 22.4167 45 19.1667C45 16.8333 43.6667 14.8333 41.6667 13.9167Z" fill="#FC0505" />
                    </svg>
                          <h3>Calibración</h3>
                              <p>Prueba de protecciones a motores principales y generadores, con instrumentos certificados de medición.</p>
                </div></Link>
                <Link to="/websupervisor">
                <div className="service-item service-item-last">
                    <svg width="39" height="37" viewBox="0 0 39 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M0 31.7704V37H5.55162C5.55162 34.1108 3.06726 31.7704 0 31.7704ZM0 20.917V26.1466C5.5516 26.1466 11.242 31.3758 11.242 36.6051H16.7936C16.7936 28.7611 8.3274 20.917 0 20.917ZM0 0V5.22958C19.4306 5.22958 33.4484 18.3025 33.4484 36.6051H39C39 15.6879 22.2064 0 0 0ZM27.8968 36.6051H22.3452C22.3452 23.5318 11.1032 15.6881 0 15.6881V10.4585C16.6548 10.4585 27.8968 20.9172 27.8968 36.6051Z" fill="#FC0505" />
                    </svg>
                              <h3>Monitoreo online</h3>
                                  <p>Sistema para la gestión remota de Motores Diesel y equipos eléctricos, con alertas, análisis de datos, y herramientas para optimizar ingresos y reducir costos de mantenimiento.</p>
                </div>
                </Link>
            </div>
        </div>
        <div className="imagen-carrousel">
            <img src={Servicios} alt="Control ship & Automation Systems" className="image-style" />
            <p className="text-overlay azonix"><span className='azonix'style={{ color: 'red' }}>C</span>ontrol ship & <span style={{ color: 'red' }}>A</span>utomation <span style={{ color: 'red' }}>S</span>ystems</p>
        </div>
    </div>
      </>
  );
};

export default HomeSection;
