import React, { useState, useRef, useEffect } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import logo from './img/logo_interphase.png'; // Cambia la ruta al logo según corresponda

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const catMenu = useRef(null);

  const handleContactClick = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (catMenu.current && !catMenu.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <nav className="navbar">
      <div className="navbar-menu" ref={catMenu}>
        <button className="menu-button" onClick={toggleMenu}>
          <div className="menu-icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <span className="menu-text azonix">Menu</span>
        </button>
        <div className={`menu-dropdown ${isMenuOpen ? 'open' : ''}`}>
          <Link to="/Home">Inicio</Link>
          <Link to="/monitoreo-alarmas">Monitoreo Alarmas</Link>
          <Link to="/automatizacion-generadores">Automatización Generadores</Link>
          <Link to="/medidores-de-fuerza">Medidores de Fuerza</Link>
          <Link to="/luces-de-navegacion">Panel Luces de Navegación</Link>
          <Link to="/mantenimiento-generadores">Mantenimiento Generadores</Link>
          <Link to="/calibracion">Calibración</Link>
          <Link to="/websupervisor">Monitoreo Online</Link>
        </div>
      </div>
      <div className="navbar-logo">
        <img src={logo} alt="Interphase Electric" />
      </div>
      <div className="navbar-contact">
        <button onClick={handleContactClick} className='azonix'>
          <p>CONTACTO</p>
          <svg className="mail-icon" width="40" height="40" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_356_150)">
              <path d="M45.833 9.16669H9.16634C6.64551 9.16669 4.60592 11.2292 4.60592 13.75L4.58301 41.25C4.58301 43.7709 6.64551 45.8334 9.16634 45.8334H45.833C48.3538 45.8334 50.4163 43.7709 50.4163 41.25V13.75C50.4163 11.2292 48.3538 9.16669 45.833 9.16669ZM45.833 18.3334L27.4997 29.7917L9.16634 18.3334V13.75L27.4997 25.2084L45.833 13.75V18.3334Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_356_150">
                <rect width="55" height="55" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;

