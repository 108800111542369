import React from 'react';
import { Route, useLocation, BrowserRouter as Router, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeSection from './pages/home/Home';
import MonitoreoAlarmas from './pages/monitoreo_a/MonitoreoA.js';
import AutomatizacionGeneradores from './pages/automatizacion_g/AutomatizacionG.js';
import Medidoresf from './pages/medidores_f/MedidoresF.js';
import Luces from './pages/luces/Luces.js';
import Mantenimiento from './pages/Mantenimiento_g/MantenimientoG.js';
import Calibracion from './pages/calibracion/Calibracion.js';
import Websupervisor from './pages/monitoreo_online/MonitoreoO.js';
import './App.css';  // Asegúrate de importar tus estilos
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import ScrollToTop from "./components/up.js"; // Importa el componente
import { useState, useEffect } from 'react';
import Loading from './components/Loading.js'; // Importa el componente de carga



function AppContent() {
  const location = useLocation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una llamada a la API o un proceso de carga
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Simula 2 segundos de carga

    return () => clearTimeout(timer);
  },[location]);

  if (loading) {
    return <Loading />;
  }

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={location.key}
        classNames="rect-fade"
        timeout={500}  // Duración de la transición
      >
        <div>
          <Header />
          <Routes location={location}>
            <Route path="/" element={<HomeSection />} />
            <Route path="/Home" element={<HomeSection />} />
            <Route path="/monitoreo-alarmas" element={<MonitoreoAlarmas />} />
            <Route path="/automatizacion-generadores" element={<AutomatizacionGeneradores />} />
            <Route path="/medidores-de-fuerza" element={<Medidoresf />} />
            <Route path="/luces-de-navegacion" element={<Luces />} />
            <Route path="/mantenimiento-generadores" element={<Mantenimiento />} />
            <Route path="/calibracion" element={<Calibracion />} />
            <Route path="/websupervisor" element={<Websupervisor />} />
          </Routes>
          <Footer />
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AppContent />
    </Router>
  );
}

export default App;

